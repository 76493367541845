@import '@/assets/scss/mixin.scss';

.content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1440px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;

  @include respond-to(920px) {
    flex-direction: column-reverse;
    justify-content: center;
  }

  @include respond-to(580px) {
    padding-top: 100px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include boldText(200px, 280px);
  color: #030304;
  margin-bottom: 54px;
  margin-top: 0;

  @include respond-to(720px) {
    @include boldText(140px, 180px);
  }
}

.describe {
  @include mediumText(24px, 34px);
  color: #a6a6a6;
  margin-bottom: 54px;

  @include respond-to(720px) {
    @include mediumText(20px, 24px);
  }
}

.back {
  @include defaultStyleButton;
  width: 100%;
  max-width: 167px;
  text-align: center;
}

.image {
}
